<template>
  <div
    class="drag-inputFile mb-8"
    v-bind:class="(disableDrag) ? 'disabled':''"
    @drop.stop.prevent="onDropFile($event)"
    @dragover.prevent
    @dragenter.prevent
  >
    <label :for="idFile" class="select"></label>
    <input
      @change="changeFile($event)"
      :id="idFile"
      class="input-file"
      accept=".png, .jpg"
      type="file"
    />
    <div
      class="img-preview"
      v-if="urlTemporal"
      v-bind:style="{ 'background-image': 'url(' + urlTemporal + ')' }"
    ></div>
    <v-icon small class="editBtn" v-if="urlTemporal">mdi-pencil</v-icon>
    <v-icon small class="deleteBtn" @click="removeFile()" v-if="urlTemporal"
      >mdi-delete</v-icon
    >
    <div class="upload">
      <v-icon dense class="mb-2">mdi-pencil</v-icon>
      <p class="label-black">Seleccionar archivo</p>
      <p class="label-instruccion">o arrastra una imagen aquí.</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    idFile: String,
    urlImagen: {
      type: String,
      default: "",
    },
    disableDrag:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      urlTemporal: this.urlImagen,
    };
  },
  watch: {
    urlImagen: function (newVal, oldVal) {
      this.urlTemporal = newVal;
    },
  },
  methods: {
    onDropFile(event) {
      const file = event.dataTransfer.files[0];
      this.processFile(file);
    },
    removeFile() {
      this.urlTemporal = "";
      this.$emit("obtieneFile", null);
    },
    changeFile(event) {
      const file = event.target.files[0];
      this.processFile(file);
    },
    processFile(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.imageProcess(file);
            this.$emit("obtieneFile", file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb",
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg",
        });
      }
    },
    imageProcess(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.urlTemporal = e.target.result;
        };
      } else {
        this.urlTemporal = require("@/assets/default.png");
      }
    },
  },
};
</script>