<template>
  <div class="container-contentNueva px-9">
    <h2 class="mb-8">{{ dataPlatillo.title }}</h2>
    <v-form
      ref="formPlatilloUpdate"
      v-model="valid"
      @submit.prevent="actualizaPlatilloSet()"
      lazy-validation
      class="mb-6"
    >
      <v-text-field
        color="black"
        v-model="dataPlatillo.title"
        :rules="nameRules"
        label="Nombre del artículo*"
        required
        outlined
      ></v-text-field>
      <v-textarea
        v-model="dataPlatillo.descripcion"
        no-resize
        color="black"
        outlined
        rows="2"
      >
        <template v-slot:label>
          <div>
            Descripción
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-textarea>
      <v-text-field
        v-model="dataPlatillo.precio"
        :rules="numberRules"
        color="black"
        label="Precio*"
        prefix="$"
        outlined
        required
      ></v-text-field>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">
          Otra información (opcional)
        </p>
        <v-divider></v-divider>
      </div>
      <p>Estos campos te permiten mostrar un precio por copa o unitario.</p>
      <v-row>
        <v-col col="12" sm="12" md="8" lg="6">
          <v-text-field
            v-model="dataPlatillo.labelUnitario"
            color="black"
            outlined
          >
            <template v-slot:label>
              <div>
                Etiqueta
                <small class="text-caption opcionalText negroClaro"
                  >(opcional)</small
                >
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col col="12" sm="12" md="8" lg="6">
          <v-text-field
            v-model="dataPlatillo.precioUnitario"
            :rules="numberOpcionalRules"
            color="black"
            prefix="$"
            outlined
          >
            <template v-slot:label>
              <div>
                Precio Unitario
                <small class="text-caption opcionalText negroClaro"
                  >(opcional)</small
                >
              </div>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <p>
        Este campo se utiliza para ofertas o en caso de que el precio no es
        numérico (p. ej. Según peso)
      </p>
      <v-text-field
        v-model="dataPlatillo.precioEspecial"
        color="black"
        outlined
      >
        <template v-slot:label>
          <div>
            Precio Especial
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-text-field>
      <p class="black--text text-h6">
        Imagen del producto
        <span class="text-caption opcionalText negroClaro">(opcional)</span>
      </p>
      <p>
        Esta es la imagen que aparecerá junto a tu producto, te recomendamos
        subirla en formato.jpg, o .png a un tamaño de 300 pixeles de alto x 600
        pixeles de ancho y con un máximo de 500kb
      </p>
      <DragDrop
        @obtieneFile="asignaImgPlatillo"
        :urlImagen="dataPlatillo.urlImage"
        idFile="inpImgPlatilloUpdate"
      ></DragDrop>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Categorías</p>
        <v-divider></v-divider>
      </div>
      <v-select
        :items="categorias"
        :item-text="'title'"
        :item-value="'id'"
        v-model="dataPlatillo.idCategoria"
        v-on:change="changeCategoria(dataPlatillo.idCategoria)"
        :rules="categoriaRules"
        name="categoria"
        label="Elige una categoría*"
        color="black"
        outlined
      ></v-select>
      <v-select
        v-if="filterSubsCategorias.length > 0"
        :items="filterSubsCategorias"
        :item-text="'title'"
        :item-value="'id'"
        v-model="dataPlatillo.idSubcategoria"
        name="subcategoria"
        label="Elige una Sub Categoría"
        color="black"
        outlined
      ></v-select>
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 mr-2 mt-8 btn-margin"
          :loading="loading"
          type="submit"
          >ACTUALIZAR ARTICULO</v-btn
        >
        <v-btn
          color="grayLight"
          class="black--text pr-8 pl-8 mt-8 btn-margin"
          @click="cancelarEdit"
          >Cancelar</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import DragDrop from "@/components/generales/DragDrop";
export default {
  props: {
    idRest: String,
    itemPlatillo: {
      type: Object,
      default: {},
    },
  },
  components: {
    DragDrop,
  },
  watch: {
    itemPlatillo: function (newVal, oldVal) {
      this.dataPlatillo = Object.assign({}, newVal);
      this.changeCategoria(this.dataPlatillo.idCategoria);
    },
  },
  created() {
    if (this.dataPlatillo) this.changeCategoria(this.dataPlatillo.idCategoria);
  },
  data() {
    return {
      nombreAnterior: "",
      dataPlatillo: Object.assign({}, this.itemPlatillo),
      valid: false,
      loading: false,
      imagenPlatillo: null,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      numberRules: [
        (v) =>
          Number.isInteger(Number(v)) || "El precio debe ser un número entero",
        (v) => v > 0 || "El número es obligatorio",
      ],
      categoriaRules: [(v) => !!v || "La categoría es obligatoria"],
      numberOpcionalRules: [
        (v) =>
          Number.isInteger(Number(v)) || "El precio debe ser un número entero",
      ],
      filterSubsCategorias: [],
    };
  },
  computed: {
    ...mapState("moduloRestaurantes", ["categorias", "subcategorias"]),
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["actualizaPlatillo", "getMenuView"]),
    async actualizaPlatilloSet() {
      //se validan los campos
      if (!this.$refs.formPlatilloUpdate.validate()) return;
      this.loading = true;
      const platActualiza = {
        idRestaurante: this.idRest,
        platillo: this.dataPlatillo,
        imagen: this.imagenPlatillo,
      };
      const result = await this.actualizaPlatillo(platActualiza);
      if (result.res) {
        this.imagenPlatillo = null;
        this.getMenuView();
        this.$emit("closeUpdatePlatillo", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    changeCategoria(idCategoria) {
      this.filterSubsCategorias = this.subcategorias.filter(
        (item) => item.idCategoria == idCategoria
      );
    },
    asignaImgPlatillo(file) {
      this.imagenPlatillo = file;
      if (file == null) {
        this.dataPlatillo.urlImage = "";
      }
    },
    cancelarEdit() {
      this.$emit("closeUpdatePlatillo", "");
    },
  },
};
</script>