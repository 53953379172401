<template>
  <v-form
    ref="formSubCrear"
    @submit.prevent="agregarSubcategorias()"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      color="black"
      :rules="nameRules"
      v-model="nombre"
      label="Nombre de la Subcategoría*"
      required
      outlined
    ></v-text-field>
    <div class="d-flex justify-end">
      <v-btn color="primary" class="white--text mr-2" :loading="loading" type="submit">
        AGREGAR
        <v-icon right dark class="ml-0 ml-2"> mdi-plus </v-icon>
      </v-btn>
      <v-btn color="grayLight" class="black--text" @click="cancelarAgregar()"
        >Cancelar</v-btn
      >
    </div>
  </v-form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valid: false,
      nombre: "",
      nameRules: [(v) => !!v || "El nombre es requerido"],
      loading: false,
    };
  },
  props: {
    idRestaurant: {
      type: String,
      default: "",
    },
    idCat: {
      type: String,
      default: "",
    },
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["creaSubCategoria"]),
    agregarSubcategorias() {
      //se validan los campos
      if (!this.$refs.formSubCrear.validate()) return;
      const subNew = {
        title: this.nombre,
        idCategoria: this.idCat,
      };
      if (this.idCat) {
        this.guardarCategory(subNew);
      } else {
        this.$emit("saveSub", subNew);
        this.$emit("closeSub", "");
        this.reset();
      }
    },
    async guardarCategory(categoryData) {
      const paramSub = {
        idRestaurante: this.idRestaurant,
        ...categoryData,
      };
      this.loading = true;
      const result = await this.creaSubCategoria(paramSub);
      if (result.res) {
        categoryData.id = result.idCreated;
        this.$emit("saveSub", categoryData);
        this.$emit("closeSub", "");
        this.reset();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agrego correctamente",
        });
      } else {
         this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    cancelarAgregar() {
      this.reset();
      this.$emit("closeSub", "");
    },
    reset() {
      this.$refs.formSubCrear.reset();
    },
  },
};
</script>