<template>
  <v-container fluid>
    <v-row class="pt-8">
      <v-col col="12" sm="12" md="8" lg="6">
        <v-tabs v-model="tab">
          <v-tab>INFORMACIÓN</v-tab>
          <v-tab :disabled="!informacionRestaurante">MENÚ</v-tab>
          <v-tab :disabled="!categorias.length > 0">DISEÑO</v-tab>
          <v-tab :disabled="!categorias.length > 0">Código QR</v-tab>
        </v-tabs>
      </v-col>
      <v-col col="12" sm="12" md="8" lg="6">
        <div class="d-flex justify-end align-center">
          <v-btn
            
            color="primary"
            class="white--text pr-8 pl-8 btn-margin"
            target="_blank"
            v-if="informacionRestaurante"
            :to="{ name: 'Menu', params: { id: informacionRestaurante.slug } }"
            >VISUALIZAR MENÚ</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <!-- informacion general -->
        <v-row>
          <v-col col="12" sm="12" md="8" lg="6">
            <v-sheet color="lighten-4" class="pt-6">
              <v-skeleton-loader
                v-show="showSkeInfo"
                class="mx-auto"
                max-width="80%"
                max-height="500px"
                type="list-item-avatar-three-line, list-item-avatar-three-line, article, article, actions"
              ></v-skeleton-loader>
            </v-sheet>
            <ActualizarInfoRestaurante
              :dataInformacion="informacionRestaurante"
              v-show="!showSkeInfo && !showCrearInfo"
            ></ActualizarInfoRestaurante>
            <CrearInformacionGeneral
              @saveIdCreado="asignaIdCreadoRestaurante"
              v-show="!showSkeInfo && showCrearInfo"
            ></CrearInformacionGeneral>
          </v-col>
          <v-col col="12" sm="12" md="8" lg="6">
            <v-skeleton-loader
              v-show="showSkeInfo"
              class="mx-auto"
              max-width="80%"
              max-height="500px"
              type="list-item-avatar-three-line, list-item-avatar-three-line, article, article, actions"
            ></v-skeleton-loader>
            <DetalleInformacionGeneral
              v-show="!showSkeInfo"
              v-if="informacionRestaurante"
            ></DetalleInformacionGeneral>
          </v-col>
        </v-row>
        <!-- informacion general -->
      </v-tab-item>
      <!-- menu crud -->
      <v-tab-item>
        <div class="px-9 py-6">
          <div class="row">
            <v-col col="12" sm="12" md="8" lg="6">
              <div class="content-principal">
                <div class="d-flex align-center justify-space-between mb-12">
                  <h2>2. Tu menú</h2>
                  <template v-if="membresiaUsuario && membresiaUsuario.subir">
                    <div
                      class="button-subir"
                      :class="[categorias.length > 0 ? 'disabled' : '']"
                    >
                      <span class="text-body-1 title-btn-excel">Beta</span>
                      <v-btn
                        color="green"
                        class="white--text mr-2"
                        @click="dialogSubir = true"
                      >
                        IMPORTAR MENÚ (XLS)
                      </v-btn>
                    </div>
                  </template>
                </div>
                <div class="d-flex flex-wrap">
                  <v-btn
                    color="black"
                    outlined
                    class="black--text mr-2"
                    @click="agregarCategoriaTab()"
                  >
                    AGREGAR CATEGORÍA
                    <v-icon right dark> mdi-plus </v-icon>
                  </v-btn>
                  <v-btn
                    color="black"
                    outlined
                    class="black--text"
                    @click="agregarArticuloTab()"
                    :disabled="!categorias.length > 0"
                  >
                    AGREGAR ARTÍCULO
                    <v-icon right dark> mdi-plus </v-icon>
                  </v-btn>
                </div>
                <!-- modal confirmar subir -->
                <v-row justify="center">
                  <v-dialog v-model="dialogSubir" max-width="500px">
                    <v-card>
                      <v-card-title>
                        <p class="alert-mark text-body-1">
                          Esta funcionalidad solo esta disponible cuando no
                          tienes categorías o platillos en tú menú.
                        </p>
                      </v-card-title>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="errorColor"
                          text
                          @click="dialogSubir = false"
                          >Cancelar</v-btn
                        >
                        <v-btn color="primary" text @click="mostrarSubirMenu()"
                          >Confirmar</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
                <!-- modal confirmar subir -->
                <VacioCategorias
                  v-if="categorias.length == 0"
                ></VacioCategorias>
                <div class="content-tabs-me mt-12">
                  <OrdenarCategorias v-if="categorias.length >= 2" @showOption="mostrarOrdenarOpcion"></OrdenarCategorias>
                  <VisualizarCategorias
                    :categoriasData="menuView"
                    :idRest="id"
                    @addProductoCategory="showAddProductoCategory"
                    @actualizaItemCat="showInfoSelectedCategoria"
                    @editItemCat="showEditCategoria"
                    @editItemPlatillo="editSetPlatillo"
                    @nextDiseno="saltarMenu"
                    @resetDeleteCat="resetCategoriasDelete"
                    v-if="categorias.length > 0"
                    @actualizaPlatillo="showInfoSelectedPlatillo"
                  ></VisualizarCategorias>
                </div>
              </div>
            </v-col>
            <v-col col="12" sm="12" md="8" lg="6">
              <ReordenarCategorias v-if="categorias.length > 0 && showReorder" :idRest="id" @closeReorder="cancelUpdateCategory"></ReordenarCategorias>
              <AgregarPlatillo
                @closeNuevoPlatillo="hideAll"
                v-if="showNuevoPlatillo"
                :idCategoryFilter="idCategoryAdd"
                :idRest="id"
                @closeNewPlat="hideAll"
              ></AgregarPlatillo>
              <ActualizarPlatillo
                :idRest="id"
                @closeUpdatePlatillo="cancelUpdatePlatillo"
                :itemPlatillo="itemUpdatePlatillo"
                v-show="showUpdatePlatillo"
              ></ActualizarPlatillo>
              <NuevaCategoria
                :idRest="id"
                @closeNuevaCategoria="finishCategorySave"
                @closeNewCat="hideAll"
                v-if="showCategoria && nuevaCategoria"
              ></NuevaCategoria>
              <UpdateCategoria
                :idRest="id"
                :itemPassCategoria="itemUpdateCategoria"
                @closeUpdate="cancelUpdateCategory"
                v-if="showCategoria && showUpdateCategoria"
              ></UpdateCategoria>
              <MuestraInfoCategoria
                :idRest="id"
                :itemPassCategoria="itemCategoria"
                @editUpdate="actualizarCategoria"
                @closeDelete="finishDeleteCategory"
                v-if="showCategoria && showInfoCategoria"
              ></MuestraInfoCategoria>
              <MuestraInfoPlatillo
                :idRest="id"
                :itemPassPlatillo="itemPlatilloPass"
                @editUpdate="asignUpdatePlatillo"
                @closeDelete="finishDeletePlatillo"
                v-if="showCategoria && showInfoPlatillo"
              ></MuestraInfoPlatillo>
              <SubirMenu
                v-if="showSubirMenu"
                :idRest="id"
                @closeSubir="getDataSubir"
                @cancelarSubir="hideAll"
              ></SubirMenu>
            </v-col>
          </div>
        </div>
      </v-tab-item>
      <!-- menu crud -->
      <!-- diseño -->
      <v-tab-item>
        <div class="px-9 py-6">
          <div class="row">
            <v-col col="12" sm="12" md="8" lg="6">
              <div class="content-principal">
                <div class="d-flex justify-space-between align-center mb-8">
                  <h2>3. Tu diseño</h2>
                  <v-btn
                    color="black"
                    outlined
                    class="black--text mr-2"
                    @click="saltarDiseño()"
                  >
                    OMITIR
                  </v-btn>
                </div>
                <CrearConfigRestaurante
                  :idRest="id"
                  :dataMenu="informacionRestaurante"
                  @cambiarQr="nextQr"
                ></CrearConfigRestaurante>
              </div>
            </v-col>
            <v-col col="12" sm="12" md="8" lg="6">
              <ViewMobileConfig
                :dataVisual="informacionRestaurante"
              ></ViewMobileConfig>
            </v-col>
          </div>
        </div>
      </v-tab-item>
      <!-- diseño -->
      <!-- QR -->
      <v-tab-item>
        <div class="px-9 py-6">
          <div class="row">
            <v-col col="12" sm="12" md="8" lg="6">
              <div class="content-principal">
                <h2 class="mb-12">4. Tu código QR</h2>
                <div class="d-flex align-center mb-4">
                  <p class="text-caption negroClaro mb-0 mr-1">
                    Descarga aquí tu código QR
                  </p>
                  <v-divider></v-divider>
                </div>
                <DescargarQr
                  :restauranteInfo="informacionRestaurante"
                  v-if="informacionRestaurante"
                ></DescargarQr>
              </div>
            </v-col>
            <v-col col="12" sm="12" md="8" lg="6"> </v-col>
          </div>
        </div>
      </v-tab-item>
      <!-- QR -->
    </v-tabs-items>
  </v-container>
</template>
<script>
import CrearInformacionGeneral from "@/components/restaurante/CrearInformacionGeneral";
import ActualizarInfoRestaurante from "@/components/restaurante/ActualizarInfoRestaurante";
import DetalleInformacionGeneral from "@/components/restaurante/DetalleInformacionGeneral";
import NuevaCategoria from "@/components/restaurante/NuevaCategoria";
import UpdateCategoria from "@/components/restaurante/UpdateCategoria";
import VisualizarCategorias from "@/components/restaurante/VisualizarCategorias";
import VacioCategorias from "@/components/restaurante/VacioCategorias";
import AgregarPlatillo from "@/components/restaurante/AgregarPlatillo";
import ActualizarPlatillo from "@/components/restaurante/ActualizarPlatillo";
import CrearConfigRestaurante from "@/components/restaurante/CrearConfigRestaurante";
import ViewMobileConfig from "@/components/restaurante/ViewMobileConfig";
import MuestraInfoCategoria from "@/components/restaurante/MuestraInfoCategoria";
import MuestraInfoPlatillo from "@/components/restaurante/MuestraInfoPlatillo";
import DescargarQr from "@/components/restaurante/DescargarQr";
import SubirMenu from "@/components/funcionalidades/SubirMenu";
import OrdenarCategorias from "@/components/restaurante/OrdenarCategorias";
import ReordenarCategorias from "@/components/restaurante/ReordenarCategorias";

import { mapActions, mapState } from "vuex";
export default {
  components: {
    CrearInformacionGeneral,
    DetalleInformacionGeneral,
    ActualizarInfoRestaurante,
    NuevaCategoria,
    VisualizarCategorias,
    VacioCategorias,
    UpdateCategoria,
    AgregarPlatillo,
    ActualizarPlatillo,
    CrearConfigRestaurante,
    ViewMobileConfig,
    MuestraInfoCategoria,
    MuestraInfoPlatillo,
    DescargarQr,
    SubirMenu,
    OrdenarCategorias,
    ReordenarCategorias
  },
  data() {
    return {
      id: this.$route.params.id,
      tab: null,
      tabMenu: null,
      loading: false,
      showSkeInfo: false,
      showCategoria: false,
      showArticulo: false,
      nuevaCategoria: true,
      itemCategoria: null,
      itemUpdateCategoria: null,
      itemPlatilloPass: null,
      itemUpdatePlatillo: null,
      showNuevoPlatillo: false,
      showUpdatePlatillo: false,
      idCategoryAdd: null,
      showCrearInfo: true,
      loadingActivar: false,
      showInfoCategoria: false,
      showInfoPlatillo: false,
      showSubirMenu: false,
      showUpdateCategoria: false,
      dialogSubir: false,
      showReorder: false
    };
  },
  created() {
    if (this.id != undefined || this.id != null) {
      this.getInitData(this.id);
      this.showCrearInfo = false;
    } else {
      this.resetMenuView();
    }
  },
  computed: {
    ...mapState("moduloRestaurantes", [
      "informacionRestaurante",
      "categorias",
      "platillos",
      "subcategorias",
      "menuView",
    ]),
    ...mapState("adminSuscripciones", ["membresiaUsuario"]),
  },
  methods: {
    ...mapActions("moduloRestaurantes", [
      "obtieneInformacionRestaurante",
      "obtieneCategoriasRestaurante",
      "obtienePlatillos",
      "obtieneSubCategoriasRestaurante",
      "getMenuView",
      "resetMenuView",
      "activarRestaurante",
    ]),
    closeDeleteSubir() {
      this.dialogSubir = false;
    },
    async changeEstatusRestaurante() {
      this.loadingActivar = true;
      const result = await this.activarRestaurante({ id: this.id });
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se activo correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingActivar = false;
    },
    async obtieneInfoRestaurante(idParam) {
      this.showSkeInfo = true;
      const result = await this.obtieneInformacionRestaurante(idParam);
      if (result.res) {
        this.showSkeInfo = false;
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
    },
    agregarCategoriaTab() {
      this.showCategoria = true;
      this.showArticulo = false;
      this.nuevaCategoria = true;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    agregarArticuloTab() {
      this.idCategoryAdd = null;
      this.showCategoria = false;
      this.showArticulo = true;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = true;
      this.showUpdatePlatillo = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    mostrarOrdenarOpcion(){
      this.showCategoria = true;
      this.showReorder = true;
      this.showUpdateCategoria = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
    },
    actualizarCategoria() {
      this.showCategoria = true;
      this.showUpdateCategoria = true;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    showEditCategoria(item) {
      this.itemUpdateCategoria = item;
      this.showCategoria = true;
      this.showUpdateCategoria = true;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    showAddProductoCategory(id) {
      this.showInfoPlatillo = true;
      this.showArticulo = true;
      this.showCategoria = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = true;
      this.showUpdatePlatillo = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showSubirMenu = false;
      this.idCategoryAdd = id;
      this.showReorder = false;
    },
    showInfoSelectedCategoria(item) {
      this.showUpdateCategoria = false;
      this.showInfoCategoria = true;
      this.showInfoPlatillo = false;
      this.itemCategoria = item;
      this.itemUpdateCategoria = item;
      this.showCategoria = true;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    showInfoSelectedPlatillo(item) {
      this.itemPlatilloPass = item;
      this.itemUpdatePlatillo = item;
      this.showInfoPlatillo = true;
      this.showCategoria = true;
      this.showInfoCategoria = false;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showUpdateCategoria = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    asignUpdatePlatillo() {
      this.showArticulo = true;
      this.showUpdatePlatillo = true;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showCategoria = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    editSetPlatillo(item) {
      this.itemUpdatePlatillo = item;
      this.showArticulo = true;
      this.showUpdatePlatillo = true;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showCategoria = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    cancelUpdateCategory() {
      this.showCategoria = true;
      this.showInfoCategoria = false;
      this.showUpdateCategoria = false;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showSubirMenu = false;
      this.showReorder = false;
    },
    cancelUpdatePlatillo() {
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showCategoria = true;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showSubirMenu = false;
      this.itemUpdateCategoria = null;
      this.showReorder = false;
    },
    hideAll() {
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showCategoria = false;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showSubirMenu = false;
      this.itemCategoria = null;
      this.itemUpdateCategoria = null;
      this.itemPlatilloPass = null;
      this.itemUpdatePlatillo = null;
      this.dialogSubir = false;
      this.showReorder = false;
    },
    mostrarSubirMenu() {
      if (this.categorias.length > 0) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title:
            "Esta funcionalidad solo esta disponible cuando no tienes categorías o platillos en tú menú.",
        });
        return;
      }
      this.dialogSubir = false;
      this.showSubirMenu = true;
      this.showCategoria = false;
      this.showUpdateCategoria = false;
      this.showInfoCategoria = false;
      this.showInfoPlatillo = false;
      this.showArticulo = false;
      this.nuevaCategoria = false;
      this.showNuevoPlatillo = false;
      this.showUpdatePlatillo = false;
      this.showReorder = false;
    },
    finishCategorySave() {
      this.hideAll();
      this.getDataCategorys(this.id);
    },
    async finishDeleteCategory() {
      this.hideAll();
      this.getDataDeleteCategory(this.id);
    },
    async finishDeletePlatillo() {
      this.hideAll();
      this.getMenuView();
      // this.getDataDeletePlatillo(this.id);
    },
    async getDataCategorys(idRestaurante) {
      await this.obtieneCategoriasRestaurante(idRestaurante);
      await this.obtieneSubCategoriasRestaurante(idRestaurante);
      this.getMenuView();
    },
    async getDataDeleteCategory(idRestaurante) {
      // await this.obtieneCategoriasRestaurante(idRestaurante);
      // await this.obtieneSubCategoriasRestaurante(idRestaurante);
      // await this.obtienePlatillos(idRestaurante);
      this.getMenuView();
    },
    async getDataSubir() {
      this.hideAll();
      await this.obtieneCategoriasRestaurante(this.id);
      await this.obtieneSubCategoriasRestaurante(this.id);
      await this.obtienePlatillos(this.id);
      this.getMenuView();
    },
    resetCategoriasDelete() {
      this.hideAll();
      this.getMenuView();
    },
    async getDataDeletePlatillo(idRestaurante) {
      await this.obtienePlatillos(idRestaurante);
      this.getMenuView();
    },
    async getInitData(idRestaurante) {
      await this.obtienePlatillos(idRestaurante);
      await this.obtieneInfoRestaurante(idRestaurante);
      await this.obtieneCategoriasRestaurante(idRestaurante);
      await this.obtieneSubCategoriasRestaurante(idRestaurante);
      this.getMenuView();
    },
    saltarDiseño() {
      this.tab = 3;
    },
    saltarMenu() {
      this.tab = 2;
    },
    nextQr() {
      this.tab = 3;
    },
    asignaIdCreadoRestaurante(idParam) {
      this.id = idParam;
      this.$router.replace({
        name: "InformacionRestaurante",
        params: { id: this.id },
      });
      this.showCrearInfo = false;
      this.tab = 1;
    },
  },
};
</script>
<style lang="scss">
.alert-mark {
  padding: 16px;
  color: #ff0000;
  background-color: rgba($color: #ff0000, $alpha: 0.1);
  border-radius: 4px;
}
.disable-me {
  background-color: rgba($color: #000000, $alpha: 0.12);
  border: 1px solid rgba(0, 0, 0, 0.26);
  span {
    color: rgba($color: #000000, $alpha: 0.26);
  }
}
.content-principal {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  padding-right: 35px;
  min-height: 60vh;
}
.button-subir {
  background-color: #4caf5033;
  padding: 12px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  .title-btn-excel {
    display: inline-block;
    margin-right: 8px;
    color: rgba($color: #000000, $alpha: 0.5);
  }
  .inner-excel {
    background-color: #4caf50;
    color: #ffffff;
    padding: 8px;
    border-radius: 4px;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
}
</style>