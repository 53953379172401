<template>
  <div class="container-contentNueva px-9">
    <h2 class="mb-8">{{ categoriaItem.title }}</h2>
    <v-form
      ref="formUpdateCategory"
      v-model="valid"
      @submit.prevent="guardarCategoria()"
      lazy-validation
      class="mb-6"
    >
      <v-text-field
        color="black"
        v-model="categoriaItem.title"
        :rules="nameRules"
        label="Nombre de la categoría*"
        required
        outlined
      ></v-text-field>
      <p class="black--text text-h6">Imagen de la categoría</p>
      <p>
        Esta es la imagen que aparecerá como portada de la categoría, te recomendamos subirla en formato.jpg, o .png a un tamaño de 300 pixeles de alto x 1200 pixeles de ancho y con un máximo de 500kb
      </p>
      <DragDrop
        @obtieneFile="asignaImgCategoria"
        :urlImagen="categoriaItem.urlImage"
        idFile="inputImgCategoriaUpdate"
      ></DragDrop>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Subcategorías</p>
        <v-divider></v-divider>
      </div>
      <p class="black--text text-h6">Subcategoría</p>
      <p>Puedes agregar subcategorías para cada categoría</p>
      <ListaSubcategoriasLevel1
        @removeSub="quitSub"
        @editarSub="setEditSub"
        :idRestaurant="idRest"
        :listSubcategorias="categoriaItem.subcategorias"
        v-if="categoriaItem.subcategorias.length > 0"
      ></ListaSubcategoriasLevel1>
      <v-btn
        color="black"
        text
        class="black--text"
        v-show="!mostrarAddCategoria && !mostrarUpdateSub"
        @click="showAddSubcategorias()"
      >
        <v-icon right dark class="ml-0 mr-2"> mdi-plus </v-icon>
        AGREGAR SUBCATEGORÍA
      </v-btn>
      <AgregarSubcategorias
        :idRestaurant="idRest"
        v-show="mostrarAddCategoria"
        @closeSub="closeAddSubcategorias"
        @saveSub="addSubcategoria"
        :idCat="categoriaItem.id"
      ></AgregarSubcategorias>
      <UpdateSubcategorias
        :idRestaurant="idRest"
        v-if="editSub"
        @updateEdit="updateItem"
        @closeEditSub="closeUpSub"
        :dataSub="editSub"
        v-show="mostrarUpdateSub"
      >
      </UpdateSubcategorias>
      <!-- <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Color</p>
        <v-divider></v-divider>
      </div> -->
      <!-- <p class="black--text text-h6">Color de la categoría</p>
      <v-color-picker
        dot-size="20"
        hide-mode-switch
        mode="hexa"
        swatches-max-height="200"
        v-model="categoriaItem.colorCaja"
      ></v-color-picker>
      <p class="black--text text-h6">Color del texto de la categoría</p>
      <p>
        Puedes elegir un color de la tipografía principal, te recomendamos
        elegir un buen contraste
      </p> -->
      <!-- <v-color-picker
        dot-size="20"
        hide-mode-switch
        mode="hexa"
        swatches-max-height="200"
        v-model="categoriaItem.color"
      ></v-color-picker> -->
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 mr-2 mt-8 btn-margin"
          :loading="loading"
          type="submit"
          >Actualizar categoría</v-btn
        >
        <v-btn
          color="grayLight"
          class="black--text pr-8 pl-8 mt-8 btn-margin"
          @click="cancelarEdit"
          >Cancelar</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import DragDrop from "@/components/generales/DragDrop";
import { mapActions } from "vuex";
import ListaSubcategoriasLevel1 from "@/components/restaurante/ListaSubcategoriasLevel1";
import AgregarSubcategorias from "@/components/restaurante/AgregarSubcategorias";
import UpdateSubcategorias from "@/components/restaurante/UpdateSubcategorias";
export default {
  props: {
    idRest: String,
    itemPassCategoria: {
      type: Object,
      default: {},
    },
  },
  watch: {
    itemPassCategoria: function (newVal, oldVal) {
      this.categoriaItem = Object.assign({}, newVal);
    },
  },
  data() {
    return {
      loading: false,
      valid: false,
      categoriaItem: Object.assign({}, this.itemPassCategoria),
      nameRules: [(v) => !!v || "El nombre es requerido"],
      imgCategoria: null,
      mostrarAddCategoria: false,
      mostrarUpdateSub: false,
      editSub: null,
      indexEditSub: null,
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["actualizaCategoria", "getMenuView"]),
    async guardarCategoria() {
      //se validan los campos
      if (!this.$refs.formUpdateCategory.validate()) return;
      const updateTwoData = {
        idRestaurante: this.idRest,
        categoriaItemUp: this.categoriaItem,
        imagen: this.imgCategoria,
      };
      this.loading = true;
      const result = await this.actualizaCategoria(updateTwoData);
      if (result.res) {
        if(result.urlImagen){
           this.categoriaItem.urlImage = result.urlImagen;
        }
        this.imgCategoria = null;
        this.getMenuView();
        this.$emit("closeUpdate", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizo correctamente la categoría",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    cancelarEdit() {
      this.$emit("closeUpdate", "");
    },
    asignaImgCategoria(file) {
      this.imgCategoria = file;
      if (file == null) {
        this.categoriaItem.urlImage = "";
      }
    },
    addSubcategoria(item) {
      this.categoriaItem.subcategorias.push(item);
      this.getMenuView();
    },
    showAddSubcategorias() {
      this.mostrarAddCategoria = true;
      this.mostrarUpdateSub = false;
      this.editSub = null;
      this.indexEditSub = null;
    },
    closeAddSubcategorias() {
      this.mostrarAddCategoria = false;
    },
    quitSub(itemDelete) {
      this.getMenuView();
      let indexRemove = this.categoriaItem.subcategorias.indexOf(itemDelete);
      this.categoriaItem.subcategorias.splice(indexRemove, 1);
    },
    closeUpSub() {
      this.mostrarUpdateSub = false;
      this.editSub = null;
      this.indexEditSub = null;
    },
    updateItem(item) {
      this.getMenuView();
      Object.assign(this.categoriaItem.subcategorias[this.indexEditSub], item);
    },
    setEditSub(item) {
      this.indexEditSub = this.categoriaItem.subcategorias.indexOf(item);
      if (this.indexEditSub !== -1) {
        this.editSub = Object.assign({}, item);
        this.mostrarUpdateSub = true;
        this.mostrarAddCategoria = false;
      }
    },
    reset() {
      this.$refs.formUpdateCategory.reset();
    },
  },
  components: {
    DragDrop,
    ListaSubcategoriasLevel1,
    AgregarSubcategorias,
    UpdateSubcategorias,
  },
};
</script>