<template>
  <div class="container-contentNueva px-9">
    <h2 class="mb-8">Sube tu menú</h2>
    <p>
      Esta funcionalidad solo esta disponible cuando no tienes categorías o platillos en tú menú.
    </p>
    <div class="d-flex justify-end">
      <v-btn link text color="accent" href="formato.xlsx" download
        >Descargar formato</v-btn
      >
    </div>
    <v-form
      ref="formSubirMenu"
      @submit.prevent="subirInfo()"
      v-model="valid"
      lazy-validation
    >
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">
          Archivo excel
        </p>
        <v-divider></v-divider>
      </div>
      <div
        class="drag-inputFile mb-8"
        @drop.stop.prevent="onDropFile($event)"
        @dragover.prevent
        @dragenter.prevent
      >
        <label for="file-excel" class="select"></label>
        <input
          @change="changeFile($event)"
          id="file-excel"
          class="input-file"
          accept=".xlsx, .xls, .csv"
          type="file"
          ref="inputFileExc"
        />
        <v-icon small class="editBtn" v-if="fileName">mdi-pencil</v-icon>
        <v-icon small class="deleteBtn" @click="removeFile()" v-if="fileName"
          >mdi-delete</v-icon
        >
        <div class="file-show" v-if="fileName">
          <img
            class="icon-excel"
            :src="require('@/assets/icon-excel.png')"
            alt="icon upload"
          />
          <p class="text-file">{{ fileName }}</p>
        </div>
        <div class="upload">
          <img
            class="icon-file"
            :src="require('@/assets/icon-upload.svg')"
            alt="icon upload"
          />
          <p class="label-black excel">Selecciona un archivo</p>
          <p class="label-instruccion">o arrastra el archivo aquí.</p>
        </div>
      </div>
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 mr-2 mt-8 btn-margin"
          :loading="loading"
          type="submit"
          :disabled="!valid"
          >Subir</v-btn
        >
        <v-btn
          color="grayLight"
          class="black--text pr-8 pl-8 mt-8 btn-margin"
          @click="cancelarFile"
          >Cancelar</v-btn
        >
      </div>
    </v-form>
    <v-dialog v-model="processFile" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Se esta procesando el archivo...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="errorParent" v-show="errorsFound.length > 0">
      <h3>Errores detectados</h3>
      <ul class="list-errors">
        <li
          class="errorColor--text"
          v-for="(error, index) in errorsFound"
          :key="index"
        >
          {{ error }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import readXlsxFile from "read-excel-file";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      valid: false,
      categoriasUpload: [],
      restauranteRules: [(v) => !!v || "El restaurante es requerido"],
      fileName: "",
      errorsFound: [],
      processFile: false,
    };
  },
  props: {
    idRest: String,
  },
  methods: {
    ...mapActions(["subirMenu"]),
    onDropFile(event) {
      this.categoriasUpload = [];
      const file = event.dataTransfer.files[0];
      this.validateFile(file);
    },
    removeFile() {
      this.categoriasUpload = [];
      this.fileName = "";
      this.$refs.inputFileExc.value = null;
      this.valid = false;
      this.errorsFound = [];
    },
    changeFile(event) {
      this.categoriasUpload = [];
      const file = event.target.files[0];
      this.validateFile(file);
    },
    validateFile(file) {
      var ext = file.name.split(".").pop();
      if (ext == "xlsx" || ext == "xls" || ext == "csv") {
        if (file) {
          this.fileName = file.name;
          this.processExcelData(file);
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .xlsx, .xls, .csv",
        });
      }
    },
    processExcelData(file) {
      this.processFile = true;
      const schema = {
        Nombre: {
          prop: "nombre",
          type: String,
          required: true,
        },
        Descripcion: {
          prop: "descripcion",
          type: String,
        },
        Precio: {
          prop: "precio",
          type: Number,
          required: true,
        },
        EtiquetaPrecioUnitario: {
          prop: "labelUnitario",
          type: String,
        },
        PrecioUnitario: {
          prop: "precioUnitario",
          type: Number,
        },
        PrecioEspecial: {
          prop: "precioEspecial",
          type: String,
        },
        Categoria: {
          prop: "categoria",
          type: String,
          required: true,
        },
        Subcategoria: {
          prop: "subcategoria",
          type: String,
        },
      };
      readXlsxFile(file, { schema }).then((data) => {
        const errors = data.errors;
        const rows = data.rows;
        if (errors.length > 0) {
          this.removeFile();
          this.procesoError(errors);
          this.valid = false;
          this.$swal({
            showConfirmButton: false,
            timer: 3500,
            icon: "error",
            timerProgressBar: true,
            title:
              "Encontramos algunos errores, corrige antes de volver a subir gracias.",
          });
        } else {
          this.errorsFound = [];
          //recorro los datos que se cargaron
          if (rows.length > 0) {
            let categorias = [];
            let orderCategoria = 0;
            rows.forEach((element) => {
              let objectPlatillo = {};
              objectPlatillo.title = element.nombre;
              objectPlatillo.descripcion = element.descripcion
                ? element.descripcion
                : "";
              objectPlatillo.precio = element.precio;
              objectPlatillo.labelUnitario = element.labelUnitario
                ? element.labelUnitario
                : "";
              objectPlatillo.precioUnitario = element.precioUnitario
                ? element.precioUnitario
                : "";
              objectPlatillo.precioEspecial = element.precioEspecial;
              let categoriaCurrent = element.categoria;
              let subCategoriaCurrent = element.subcategoria;
              //creo el objecto categoria
              var objectCategoria = {
                title: categoriaCurrent,
                subcategorias: [],
                platillos: [],
              };
              //valido que la categoria no se encuentre repetida
              if (
                !categorias.filter((e) => e.title == categoriaCurrent).length >
                0
              ) {
                objectCategoria.order = orderCategoria;
                categorias.push(objectCategoria);
                orderCategoria++;
              }
              //recorro las categorias y asigno la subcategoria perteneciente
              if (categorias.length > 0) {
                //valido que el platillo tenga subcategoria
                if (subCategoriaCurrent) {
                  let orderSub = 0;
                  categorias.forEach((catElement) => {
                    var exitSubCat =
                      catElement.subcategorias.filter(
                        (e) => e.title == subCategoriaCurrent
                      ).length > 0;
                    //valido que la categoria no tenga la subcategoria para que no este repetida
                    //valido que la subcategoria le pertenesca la categoria
                    if (!exitSubCat && catElement.title == categoriaCurrent) {
                      //si la subcategoria no existe en esta categoria pos la inserto
                      let objSubcategoria = {};
                      objSubcategoria.title = subCategoriaCurrent;
                      objSubcategoria.platillos = [];
                      objSubcategoria.order = catElement.subcategorias.length;
                      //ademas agrego el platillo que tiene esta subcategoria que se esta agregando
                      objSubcategoria.platillos.push(objectPlatillo);
                      //lo guardo en la categoria padre
                      catElement.subcategorias.push(objSubcategoria);
                    } else if (
                      exitSubCat &&
                      catElement.title == categoriaCurrent
                    ) {
                      //en caso de que ya exista pos busco la subcategoria
                      //para solo agregar el platillo, esto me regresa el objecto de la subcategoria a insertar
                      let subCatPlatillo = catElement.subcategorias.find(
                        (e) => e.title == subCategoriaCurrent
                      );
                      subCatPlatillo.platillos.push(objectPlatillo);
                    }
                  });
                } else {
                  //en caso de que no pues inserto el platillo en la categoria
                  //xq no pertenece a una subcategoria
                  let categoriaParentPlatillo = categorias.find(
                    (e) => e.title == categoriaCurrent
                  );
                  if (categoriaParentPlatillo)
                    categoriaParentPlatillo.platillos.push(objectPlatillo);
                }
              }
            });
            this.categoriasUpload = categorias;
            console.log(this.categoriasUpload);
          }
          this.processFile = false;
          this.valid = true;
        }
      });
    },
    procesoError(errorsList) {
      errorsList.forEach((elementError) => {
        let errorType = elementError.error;
        let fila = elementError.row + 1;
        let column = elementError.column;
        let errorTraductor = "";
        let textoError = "";
        switch (errorType) {
          case "required":
            errorTraductor = "requerida";
            break;
          case "invalid":
            errorTraductor = "inválido";
            break;
          default:
            errorTraductor = "incorrecto";
            break;
        }
        textoError =
          "La columna " +
          column +
          " en la fila " +
          fila +
          " es " +
          errorTraductor;
        this.errorsFound.push(textoError);
      });
    },
    async subirInfo() {
      //se validan los campos
      if (!this.$refs.formSubirMenu.validate()) return;
      if (this.categoriasUpload.length > 0) {
        this.loading = true;
        let objSendMenu = {
          idRestaurante: this.idRest,
          categoriasMenu: this.categoriasUpload,
        };
        const result = await this.subirMenu(objSendMenu);
        if (result.res) {
          this.reset();
          this.removeFile();
          this.$emit("closeSubir", "");
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "success",
            timerProgressBar: true,
            title: "Se subio el menú correctamente.",
          });
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: result.error,
          });
        }
        this.loading = false;
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "El archivo excel es requerido.",
        });
      }
    },
    reset() {
      this.$refs.formSubirMenu.reset();
    },
    cancelarFile() {
      this.$emit("cancelarSubir", "");
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 1024px) {
  .order-data {
    order: 2;
  }
  .order-error {
    order: 1;
  }
}
.errorParent {
  width: 100%;
  padding-left: 50px;
  @media (max-width: 1024px) {
    padding-left: 0;
  }
}
.list-errors {
  list-style: none;
  margin-bottom: 50px;
  padding-left: 0 !important;
  li {
    position: relative;
    &:before {
      content: "\2014";
      position: absolute;
      margin-left: -20px;
    }
    //movil
    @media (max-width: 480px) {
      padding-left: 20px;
    }
  }
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
}
</style>