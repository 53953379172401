<template>
  <v-form
    ref="formSubUpdate"
    @submit.prevent="guardaSubCategoria()"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      color="black"
      :rules="nameRules"
      v-model="dataEditSub.title"
      label="Nombre de la Sub categoría"
      required
      outlined
    ></v-text-field>
    <div class="d-flex justify-end">
      <v-btn color="primary" :loading="loading" class="white--text mr-2" type="submit">
        ACTUALIZAR
        <v-icon right dark class="ml-0 ml-2"> mdi-plus </v-icon>
      </v-btn>
      <v-btn color="grayLight" class="black--text" @click="cancelarActualizar()"
        >Cancelar</v-btn
      >
    </div>
  </v-form>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      valid: false,
      dataEditSub: Object.assign({}, this.dataSub),
      nameRules: [(v) => !!v || "El nombre es requerido"],
      loading: false,
    };
  },
  props: {
    dataSub: {
      type: Object,
      default: {},
    },
    idRestaurant: {
      type: String,
      default: "",
    },
  },
  watch: {
    dataSub: function (newVal, oldVal) {
      this.dataEditSub = newVal;
    },
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["actualizaSubCategoria"]),
    guardaSubCategoria() {
      //se validan los campos
      if (!this.$refs.formSubUpdate.validate()) return;
      if (this.dataEditSub.id) {
        this.changeSubQuery();
      } else {
        this.$emit("updateEdit", this.dataEditSub);
        this.reset();
        this.$emit("closeEditSub", "");
      }
    },
    async changeSubQuery() {
      this.loading = true;
      const paramActualiza = {
        idRestaurante : this.idRestaurant,
        dataSub : this.dataEditSub
      }
      const result = await this.actualizaSubCategoria(paramActualiza);
      if (result.res) {
        this.$emit("updateEdit", this.dataEditSub);
        this.$emit("closeEditSub", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizo correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    cancelarActualizar() {
      this.reset();
      this.$emit("closeEditSub", "");
    },
    reset() {
      this.$refs.formSubUpdate.reset();
    },
  },
};
</script>