<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        class="white--text pr-2 pl-2 mr-2 btn-margin"
        @click.prevent="habilitarEdit"
        >EDITAR</v-btn
      >
      <v-btn
        color="removeColor"
        @click="openEliminarPlatillo()"
        class="red--text text--accent-4 pr-2 pl-2 btn-margin"
        >BORRAR</v-btn
      >
    </div>
    <h2 class="mb-8">{{ platilloItem.title }}</h2>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Información general</p>
      <v-divider></v-divider>
    </div>
    <p class="black--text text-h6">Imagen del platillo</p>
    <v-img
      max-width="100%"
      max-height="200px"
      contain
      position="left"
      :src="platilloItem.urlImage"
      v-show="platilloItem.urlImage"
      class="mb-4 img-principal-edit"
    ></v-img>
    <v-img
      max-width="100%"
      max-height="200px"
      contain
      position="left"
      :src="require('@/assets/default.png')"
      v-show="!platilloItem.urlImage"
      class="mb-4 img-principal-edit"
    ></v-img>
    <p>Descripción: {{ platilloItem.descripcion }}</p>
    <p>Precio: {{ platilloItem.precio }}</p>
    <p>Precio Unitario: {{ platilloItem.precioUnitario }}</p>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Datos alternativos</p>
      <v-divider></v-divider>
    </div>
    <p>Etiqueta/Precio Unitario: {{ platilloItem.labelUnitario }}</p>
    <p>Precio Especial: {{ platilloItem.precioEspecial }}</p>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Categoría</p>
      <v-divider></v-divider>
    </div>
    <p v-if="!platilloItem.idCategoria">Sin categoría</p>
    <p v-if="platilloItem.idCategoria">
      Nombre de la categoría: {{ getNombreCategoria }}
    </p>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Subcategoría</p>
      <v-divider></v-divider>
    </div>
    <p v-if="!platilloItem.idSubcategoria">Sin subcategoría</p>
    <p v-if="platilloItem.idSubcategoria">
      Nombre de la subcategoría: {{ getNombreSubCategoria }}
    </p>
    <!-- modal eliminar -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Estas seguro que deseas eliminar {{ nameDelete }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="closeDeleteModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              text
              @click="borrarPlatillo()"
              :loading="loadingDelete"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- modal eliminar -->
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    idRest: String,
    itemPassPlatillo: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState("moduloRestaurantes", ["categorias", "subcategorias"]),
    getNombreCategoria() {
      if (!this.categorias.length == 0) {
        let findCategoria = this.categorias.find(
          (c) => c.id == this.platilloItem.idCategoria
        );
        return findCategoria ? findCategoria.title : "";
      }
      return "";
    },
    getNombreSubCategoria() {
      if (!this.subcategorias.length == 0) {
        let findSub = this.subcategorias.find(
          (c) => c.id == this.platilloItem.idSubcategoria
        );
        return findSub ? findSub.title : "";
      }
      return "";
    },
  },
  watch: {
    itemPassPlatillo: function (newVal, oldVal) {
      this.platilloItem = Object.assign({}, newVal);
    },
  },
  data() {
    return {
      platilloItem: Object.assign({}, this.itemPassPlatillo),
      loadingDelete: false,
      dialogDelete: false,
      nameDelete: "",
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["eliminaPlatillo"]),
    openEliminarPlatillo() {
      this.dialogDelete = true;
      this.nameDelete = this.platilloItem.title;
    },
    closeDeleteModal() {
      this.dialogDelete = false;
      this.nameDelete = "";
    },
    habilitarEdit() {
      this.$emit("editUpdate", "");
    },
    async borrarPlatillo() {
      this.platilloItem.idRestaurante = this.idRest;
      this.loadingDelete = true;
      const result = await this.eliminaPlatillo(this.platilloItem);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
        this.$emit("closeDelete", "");
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
  },
};
</script>