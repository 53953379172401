<template>
  <div class="container-informacion px-9 py-6">
    <h2 class="mb-12">1. Tu información</h2>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Información general</p>
      <v-divider></v-divider>
    </div>
    <v-form
      ref="formCreateInform"
      v-model="valid"
      @submit.prevent="guardarInformacion()"
      lazy-validation
      class="mb-6"
    >
      <v-text-field
        color="black"
        v-model="nombre"
        :rules="nameRules"
        label="Nombre*"
        required
        outlined
      ></v-text-field>
      <p class="black--text text-h6 font-weight-regular">
        Tu logotipo
        <span class="text-caption opcionalText negroClaro">(opcional)</span>
      </p>
      <p>
        Puedes subir un formato.jpg, o .png con un máximo de 500kb.
      </p>
      <DragDrop @obtieneFile="asignaLogo" idFile="inputLogoCrear"></DragDrop>
      <v-textarea v-model="direccion" no-resize color="black" outlined rows="2">
        <template v-slot:label>
          <div>
            Dirección
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-textarea>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Social media</p>
        <v-divider></v-divider>
      </div>
      <p>Copia y pega la dirección (p.ej. https://www.facebook.com/CanallaAgency)</p>
      <v-text-field color="black" :rules="urlRules" v-model="facebook" outlined>
        <template v-slot:label>
          <div>
            Facebook
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-text-field>
      <p>Copia y pega la dirección (p.ej. https://www.instagram.com/canallaagency/)</p>
      <v-text-field
        color="black"
        v-model="instagram"
        label="Instagram"
        outlined
        :rules="urlRules"
      >
        <template v-slot:label>
          <div>
            Instagram
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-text-field>
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 btn-margin"
          :disabled="!valid"
          :loading="loading"
          type="submit"
          >GUARDAR INFORMACIÓN</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import DragDrop from "@/components/generales/DragDrop";
import { mapActions } from "vuex";
export default {
  data: () => ({
    valid: false,
    nombre: "",
    direccion: "",
    slug: "",
    imagenLogo: null,
    facebook: "",
    instagram: "",
    loading: false,
    nameRules: [(v) => !!v || "El nombre es requerido"],
    urlRules: [v => (!v || /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(v))|| 'La url es inválida'],
  }),
  components: {
    DragDrop,
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["creaRestaurante"]),
    async guardarInformacion() {
      const uuidGenerate = this.createUUID();
      this.generarSlug();
      // se validan los campos
      if (!this.$refs.formCreateInform.validate()) return;
      const restauranteCreate = {
        nombre: this.nombre,
        foto: this.imagenLogo,
        slug: this.slug + "-" + uuidGenerate,
        direccion: this.direccion,
        facebook: this.facebook,
        instagram: this.instagram
      };
      this.loading = true;
      const result = await this.creaRestaurante(restauranteCreate);
      if (result.res) {
        let idCreado = result.idRest;
        this.$emit("saveIdCreado", idCreado);
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente",
        });
        // this.reset();
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    generarSlug() {
      if (this.nombre == null) return;
      var slug = this.nombre
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
      this.slug = slug;
    },
    createUUID() {
      let uuid = Math.random().toString(36).slice(-6);
      return uuid;
    },
    asignaLogo(file) {
      this.imagenLogo = file;
    },
    reset() {
      this.$refs.formCreateInform.reset();
    },
  },
};
</script>
<style lang="scss">
.container-informacion {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>