<template>
  <div class="order-parent mb-12">
    <h2 class="mb-6">Reordenar Categorías</h2>
    <p>Aquí puedes ordenar las categorías en el orden que más prefieras.</p>
    <draggable v-model="listCategoria">
      <div
        v-for="categoria in listCategoria"
        :key="categoria.id"
        class="order-item"
      >
        <img
          :src="require('@/assets/move-icon.svg')"
          alt="icon move"
          class="icon-move"
        />
        {{ categoria.title }}
      </div>
    </draggable>
    <div class="d-flex justify-center">
      <v-btn
        color="primary"
        class="white--text pr-8 pl-8 mr-2 mt-8 btn-margin"
        :loading="loading"
        @click="guardarOrder()"
        >GUARDAR CAMBIOS</v-btn
      >
      <v-btn
        color="grayLight"
        class="black--text pr-8 pl-8 mt-8 btn-margin"
        @click="cancelarOrder"
        >Cancelar</v-btn
      >
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  props: {
    idRest: String,
  },
  data() {
    return {
      loading: false,
      listChange: [],
    };
  },
  components: {
    draggable,
  },
  computed: {
    ...mapState("moduloRestaurantes", ["listCategoriaChange", "categorias"]),
    listCategoria: {
      get() {
        return this.categorias;
      },
      set(value) {
        let itemsChange = [];
        var items = value.map(function (item, index) {
          const newSort = index;
          if (item.order !== newSort) {
            item.order = newSort;
            itemsChange.push(item);
          }
          return item;
        });
        this.$store.commit(
          "moduloRestaurantes/setCategoriaChange",
          itemsChange
        );
        this.$store.commit("moduloRestaurantes/setCategorias", items);
      },
    },
  },
  methods: {
    ...mapActions("moduloRestaurantes", [
      "guardarOrderCategorias",
      "getMenuView",
    ]),
    async guardarOrder() {
      this.loading = true;
      const result = await this.guardarOrderCategorias(this.idRest);
      if (result.res) {
        this.getMenuView();
        this.$store.commit("moduloRestaurantes/clearCategoriasChange", []);
        this.$emit("closeReorder", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se guardo correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    async cancelarOrder() {
      this.$store.commit("moduloRestaurantes/clearCategoriasChange", []);
      this.$emit("closeReorder", "");
    },
  },
};
</script>

<style lang="scss">
.order-parent {
  border-radius: 4px;
  width: 100%;
}
.order-item {
  background-color: #3f51b5;
  border-color: #3f51b5;
  align-items: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  display: flex;
  font-size: 0.9375rem;
  line-height: 1;
  min-height: 48px;
  outline: none;
  padding: 22px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
  color: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  cursor: move;
  .icon-move {
    width: 20px;
    margin-right: 25px;
  }
  &:not(:last-child) {
    border-bottom: 1px solid #ffffff;
  }
}
</style>