<template>
  <div>
    <div class="container-border">
      <vue-qr
        qid="imgQR"
        :callback="generaQR"
        :text="urlCurrentMenu"
        :size="200"
      ></vue-qr>
    </div>
    <div class="d-flex justify-center">
      <v-btn
        color="primary"
        class="white--text pr-8 pl-8 mr-2 mt-8 btn-margin"
        :loading="loading"
        @click="descargarQR"
        >Descargar QR</v-btn
      >
    </div>
  </div>
</template>
<script>
import VueQr from "vue-qr";
export default {
  props: {
    restauranteInfo: {
      type: Object,
      default: "",
    },
  },
  data() {
    return {
      urlCurrentMenu: "",
      urlImgQR: "",
      loading: false,
    };
  },
  components: {
    VueQr,
  },
  created() {
    this.openGenerateQR();
  },
  methods: {
    generaQR(dataUrl) {
      this.urlImgQR = dataUrl;
    },
    openGenerateQR() {
      this.urlCurrentMenu =
        "https://tumenu.mx/menu/" + this.restauranteInfo.slug;
    },
    descargarQR() {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.src = this.urlImgQR;
      ctx.canvas.width = 200;
      ctx.canvas.height = 220;
      ctx.drawImage(image, 0, 0, 200, 200);
      ctx.font = "12px Arial";
      ctx.textAlign = "center";
      ctx.fillText("Realizado por tumenu.mx", 200 / 2, 210);
      let urlCanvas = canvas.toDataURL();
      var a = document.createElement("a");
      a.href = urlCanvas;
      a.download = "qrtumenu.png";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
<style lang="scss">
.container-border {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed #bfbfbf;
  height: 300px;
  width: 100%;
}
</style>