<template>
  <div class="container-informacion px-9 py-6">
    <h2 class="mb-12">1. Tu información</h2>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Información general</p>
      <v-divider></v-divider>
    </div>
    <v-form
      ref="formInfoUpdate"
      v-model="valid"
      @submit.prevent="actualizarInformacion()"
      lazy-validation
      class="mb-6"
    >
      <v-text-field
        color="black"
        v-model="dataInformacionUpdate.nombre"
        :rules="nameRules"
        label="Nombre*"
        v-on:change="generaSlugCambio()"
        required
        outlined
      ></v-text-field>
      <v-textarea
        v-model="dataInformacionUpdate.direccion"
        no-resize
        color="black"
        outlined
        rows="2"
      >
        <template v-slot:label>
          <div>
            Dirección
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-textarea>
      <p class="black--text text-h6 font-weight-regular">
        Tu logotipo
        <span class="text-caption opcionalText negroClaro">(opcional)</span>
      </p>
      <p>
        Puedes subir un formato.jpg, o .png con un máximo de 500kb.
      </p>
      <DragDrop
        @obtieneFile="asignaLogo"
        :urlImagen="dataInformacionUpdate.foto"
        idFile="inputLogoInformacion"
      ></DragDrop>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Social media</p>
        <v-divider></v-divider>
      </div>
      <p>Copia y pega la dirección (p.ej. https://www.facebook.com/CanallaAgency)</p>
      <v-text-field
        color="black"
        v-model="dataInformacionUpdate.facebook"
        outlined
        :rules="urlRules"
      >
        <template v-slot:label>
          <div>
            Facebook
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-text-field>
      <p>Copia y pega la dirección (p.ej. https://www.instagram.com/canallaagency/)</p>
      <v-text-field
        color="black"
        v-model="dataInformacionUpdate.instagram"
        outlined
        :rules="urlRules"
      >
        <template v-slot:label>
          <div>
            Instagram
            <small class="text-caption opcionalText negroClaro"
              >(opcional)</small
            >
          </div>
        </template>
      </v-text-field>
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 btn-margin"
          :disabled="!valid"
          :loading="loading"
          type="submit"
          >ACTUALIZAR INFORMACIÓN</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import DragDrop from "@/components/generales/DragDrop";
import { mapActions } from "vuex";
export default {
  props: {
    dataInformacion: {
      type: Object,
      default: {},
    },
  },
  watch: {
    dataInformacion: function (newVal, oldVal) {
      this.dataInformacionUpdate = Object.assign({}, newVal);
    },
  },
  data: function () {
    return {
      dataInformacionUpdate: Object.assign({}, this.dataInformacion),
      valid: false,
      slug: "",
      imagenLogo: null,
      loading: false,
      nameRules: [(v) => !!v || "El nombre es requerido"],
      urlRules: [v => (!v || /https?:[0-9]*\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/.test(v))|| 'La url es inválida'],
    };
  },
  components: {
    DragDrop,
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["actualizarInfoRestaurante"]),
    async actualizarInformacion() {
      // se validan los campos
      if (!this.$refs.formInfoUpdate.validate()) return;
      const restauranteActualizar = {
        id: this.dataInformacionUpdate.id,
        nombre: this.dataInformacionUpdate.nombre,
        foto: this.dataInformacionUpdate.foto,
        slugActual: this.dataInformacionUpdate.slug,
        slug: this.slug,
        direccion: this.dataInformacionUpdate.direccion,
        facebook: this.dataInformacionUpdate.facebook,
        instagram: this.dataInformacionUpdate.instagram,
        youtube: this.dataInformacionUpdate.youtube,
        imagenFile: this.imagenLogo,
      };
      this.loading = true;
      const result = await this.actualizarInfoRestaurante(
        restauranteActualizar
      );
      if (result.res) {
        this.dataInformacionUpdate.foto = result.foto;
        this.imagenLogo = null;
        this.slug = "";
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se actualizo correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    asignaLogo(file) {
      this.imagenLogo = file;
      if (file == null) {
        this.dataInformacionUpdate.foto = "";
      }
    },
    generaSlugCambio() {
      if (this.dataInformacionUpdate.nombre == null) return;
      const uuidGenerate = this.createUUID();
      var slug = this.dataInformacionUpdate.nombre
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
      this.slug = slug + "-" + uuidGenerate;
    },
    createUUID() {
      let uuid = Math.random().toString(36).slice(-6);
      return uuid;
    },
    reset() {
      this.$refs.formInfoUpdate.reset();
    },
  },
};
</script>
<style lang="scss">
.container-informacion {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
</style>