<template>
  <!-- platillos -->
  <div>
    <v-row>
      <v-col class="col-12">
        <div class="platillos-content">
          <div
            class="card-platillo"
            v-for="(platillo, index) in platillos"
            :key="index"
          >
            <div class="container-content">
              <h3 class="title-platillo-expand" v-if="platillo.title">
                {{ platillo.title }}
              </h3>
              <p class="subtitle" v-if="platillo.descripcion">
                {{ platillo.descripcion }}
              </p>
              <div v-if="platillo.precioEspecial">
                <p class="price" v-if="platillo.precioEspecial">
                  {{ platillo.precioEspecial }}
                </p>
              </div>
              <div v-else>
                <div
                  v-if="platillo.labelUnitario && platillo.labelUnitario != ''"
                >
                  <p class="price" v-if="platillo.precio">
                    ${{ platillo.precio }}
                  </p>
                  <p class="price" v-if="platillo.precioUnitario">
                    {{ platillo.labelUnitario }}:
                  </p>
                  <p class="pice" v-if="platillo.precioUnitario">
                    ${{ platillo.precioUnitario }}
                  </p>
                </div>
                <div v-else>
                  <p class="price" v-if="platillo.precio">
                    ${{ platillo.precio }}
                  </p>
                </div>
              </div>
            </div>
            <div
              class="container-img"
              v-show="platillo.urlImage"
              v-on:click="openModal(platillo.urlImage)"
              v-bind:style="{
                'background-image': 'url(' + platillo.urlImage + ')',
              }"
            ></div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { gsap } from "gsap";
import { mapMutations } from "vuex";

export default {
  props: {
    platillos: Array,
  },
  data() {
    return {
      urlImagenModal: "",
    };
  },
  methods: {
    ...mapMutations(["setImgModalPlatillo"]),
    openModal(urlImage) {
      this.setImgModalPlatillo(urlImage);
      gsap.to(".modal-platillo", { duration: 0.6, opacity: 1, zIndex: 999 });
    },
  },
};
</script>
<style lang="scss">
.platillos-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.card-platillo {
  display: flex;
  margin-bottom: 16px;
  .container-img {
    height: 60px;
    width: 60px;
    flex-shrink: 0;
    border-radius: 50%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
  .container-content {
    display: flex;
    flex-direction: column;
    padding-right: 16px;
    flex: 1;
    .title-platillo-expand {
      font-size: 16px;
      margin-bottom: 6px;
    }
    .subtitle {
      font-size: 14px;
      margin-bottom: 6px;
    }
    .price {
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}
</style>