<template>
  <div class="container-informacion px-9 py-6">
    <h2 class="mb-12">Datos del restaurante</h2>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Información general</p>
      <v-divider></v-divider>
    </div>
    <p>Nombre del restaurante: {{ informacionRestaurante.nombre }}</p>
    <p>Slug: {{ informacionRestaurante.slug }}</p>
    <p v-if="informacionRestaurante.direccion">
      Direccion: {{ informacionRestaurante.direccion }}
    </p>
    <div class="d-flex align-center mb-4" v-if="informacionRestaurante.foto">
      <p class="text-caption negroClaro mb-0 mr-1">Logotipo</p>
      <v-divider></v-divider>
    </div>
    <img width="100%" class="img-logo-visual" :src="informacionRestaurante.foto" alt="icon edit" v-if="informacionRestaurante.foto" />
    <div
      class="d-flex align-center mb-4"
      v-if="
        informacionRestaurante.facebook ||
        informacionRestaurante.instagram ||
        informacionRestaurante.youtube
      "
    >
      <p class="text-caption negroClaro mb-0 mr-1">Redes sociales</p>
      <v-divider></v-divider>
    </div>
    <p v-if="informacionRestaurante.facebook">
      Facebook: {{ informacionRestaurante.facebook }}
    </p>
    <p v-if="informacionRestaurante.instagram">
      Instagram: {{ informacionRestaurante.instagram }}
    </p>
    <p v-if="informacionRestaurante.youtube">
      Youtube: {{ informacionRestaurante.youtube }}
    </p>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("moduloRestaurantes", ["informacionRestaurante"]),
  },
};
</script>
<style lang="scss">
.img-logo-visual{
  max-height: 200px;
  object-fit: contain;
}
</style>