<template>
  <v-form
    ref="formCreateConfigRest"
    v-model="valid"
    @submit.prevent="guardarInfoRest()"
    lazy-validation
    class="mb-6"
  >
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Imágenes</p>
      <v-divider></v-divider>
    </div>
    <p class="black--text text-h6 font-weight-regular">Tu imagen principal <span class="text-caption opcionalText negroClaro">(opcional)</span></p>
    <p>
      Esta es la imagen que aparecerá en la parte superior de tu menú, te recomendamos subirla en formato.jpg, o .png a un tamaño de 300 pixeles de alto x 600 pixeles de ancho y con un máximo de 500kb
    </p>
    <DragDrop
      @obtieneFile="asignImgPrincipal"
      idFile="inputImgPrincMenu"
      :urlImagen="dataMenu.imgPrincipal"
    ></DragDrop>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Background</p>
      <v-divider></v-divider>
    </div>
    <p class="black--text text-h6 font-weight-regular">El background <span class="text-caption opcionalText negroClaro">(opcional)</span></p>
    <p>Puedes elegir un color o una imagen para el fondo de tu menú</p>
    <v-radio-group v-model="dataMenu.tipoFondo" row v-on:change="cambiaTipo">
      <v-radio label="Color" value="color"></v-radio>
      <v-radio label="Imagen" value="imagen"></v-radio>
    </v-radio-group>
    <v-color-picker
      v-if="dataMenu.tipoFondo == 'color'"
      dot-size="20"
      hide-mode-switch
      mode="hexa"
      swatches-max-height="200"
      v-model="dataMenu.colorFondo"
    ></v-color-picker>
    <template v-if="dataMenu.tipoFondo == 'imagen'">
      <p class="black--text text-h6 font-weight-regular">Tu imagen de fondo</p>
      <p>
        Esta es la imagen que aparecera de fondo, te recomendamos subirla en
        formato.jpg, o .png a un tamaño de 1600 x 400 pixeles
      </p>
      <DragDrop
        @obtieneFile="asignImgFondoMenu"
        idFile="inputImgFondoMenu"
        :urlImagen="dataMenu.fondoImg"
      ></DragDrop>
    </template>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Barra de navegación</p>
      <v-divider></v-divider>
    </div>
    <p class="black--text text-h6 font-weight-regular">
      La barra de navegación <span class="text-caption opcionalText negroClaro">(opcional)</span>
    </p>
    <p>Elige el color que llevará la barra de navegación de tu menú</p>
    <v-color-picker
      dot-size="20"
      hide-mode-switch
      mode="hexa"
      swatches-max-height="200"
      v-model="dataMenu.colorBar"
    ></v-color-picker>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">
        Color de la tipografía principal
      </p>
      <v-divider></v-divider>
    </div>
    <p class="black--text text-h6 font-weight-regular">Tipografía principal <span class="text-caption opcionalText negroClaro">(opcional)</span></p>
    <p>
      Puedes elegir un color de la tipografía principal, te recomendamos elegir
      un buen contraste
    </p>
    <v-color-picker
      dot-size="20"
      hide-mode-switch
      mode="hexa"
      swatches-max-height="200"
      v-model="dataMenu.colorTextoPrincipal"
    ></v-color-picker>
    <div class="d-flex justify-center">
      <v-btn
        color="primary"
        class="white--text pr-8 pl-8 btn-margin mt-12"
        :disabled="!valid"
        :loading="loading"
        type="submit"
        >GUARDAR DISEÑO</v-btn
      >
    </div>
  </v-form>
</template>
<script>
import DragDrop from "@/components/generales/DragDrop";
import { mapActions } from "vuex";
export default {
  props: {
    idRest: {
      type: String,
      default: "",
    },
    dataMenu: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      valid: false,
      loading: false,
      imgPrincipal: null,
      imgFondo: null,
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["actualizaConfigRestaurante"]),
    cambiaTipo() {
      if (this.dataMenu.tipoFondo == "imagen") {
        this.dataMenu.colorFondo = "";
      } else {
        this.imgFondo = null;
        this.dataMenu.fondoImg = "";
      }
    },
    async guardarInfoRest() {
      const paramUpdate = {
        idRestaurante: this.idRest,
        ...this.dataMenu,
        imagenPricipalFile: this.imgPrincipal,
        imagenFondoFile: this.imgFondo,
      };
      //se validan los campos
      if (!this.$refs.formCreateConfigRest.validate()) return;
      this.loading = true;
      const result = await this.actualizaConfigRestaurante(paramUpdate);
      if (result.res) {
        if (result.imgPrincipal)
          this.dataMenu.imgPrincipal = result.imgPrincipal;
        if (result.fondoImg) this.dataMenu.fondoImg = result.fondoImg;
         this.$emit("cambiarQr");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se guardo correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    asignImgPrincipal(file) {
      this.imgPrincipal = file;
      if (file == null) {
        this.dataMenu.imgPrincipal = "";
      } else {
        this.setImageStaticPrincipal(file);
      }
    },
    asignImgFondoMenu(file) {
      this.imgFondo = file;
      if (file == null) {
        this.dataMenu.fondoImg = "";
      } else {
        this.setImageFondo(file);
      }
    },
    setImageStaticPrincipal(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.dataMenu.imgPrincipal = e.target.result;
      };
    },
    setImageFondo(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        this.dataMenu.fondoImg = e.target.result;
      };
    },
  },
  components: {
    DragDrop,
  },
};
</script>
<style>
.radios-center .v-input--radio-group--row {
  justify-content: center !important;
}
</style>