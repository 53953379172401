<template>
  <div class="container-parent-mobil-visual">
    <div class="container-align-mobile">
      <div class="container-phone-parent">
        <div class="img-celular">
          <img :src="require('@/assets/celular.png')" alt="" />
        </div>
        <div
          class="container-phone"
          v-bind:style="{ 'background-color': dataVisual.colorFondo }"
        >
          <div
            class="img-fondo-edit-menu"
            v-show="dataVisual.tipoFondo == 'imagen'"
            v-bind:style="{
              'background-image': 'url(' + dataVisual.fondoImg + ')',
            }"
          ></div>
          <div
            class="fondo-color-edit-menu"
            v-show="dataVisual.tipoFondo == 'color'"
            v-bind:style="{ 'background-color': dataVisual.colorFondo }"
          ></div>
          <div
            class="nav-superior"
            v-bind:style="{ 'background-color': dataVisual.colorBar }"
          >
            <div class="align-nav-phone">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                :src="dataVisual.foto"
                width="60"
              />
              <div class="buttons-socials">
                <v-btn :href="dataVisual.facebook" v-if="dataVisual.facebook" target="_blank" icon class="white--text">
                  <v-icon size="28px" class="editBtn">mdi-facebook</v-icon>
                </v-btn>
                <v-btn :href="dataVisual.instagram" v-if="dataVisual.instagram" target="_blank" icon class="white--text">
                  <v-icon size="28px" class="editBtn">mdi-instagram</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <div class="container-menu-content">
            <v-img
              max-width="100%"
              max-height="200px"
              cover
              :src="dataVisual.imgPrincipal"
              v-show="dataVisual.imgPrincipal"
              class="mb-4 img-principal-edit"
            ></v-img>
            <h2
              class="title-menu-config mb-6"
              v-bind:style="{ color: dataVisual.colorTextoPrincipal }"
            >
              Menú
            </h2>
            <div class="content-catplatillos">
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(categoria, index) in menuView"
                  :key="index"
                >
                  <v-expansion-panel-header
                    class="negroClaro"
                  >
                    <h5>{{ categoria.title }}</h5>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                  class="negroClaro"
                  >
                    <div>
                      <div
                        class="containerBanner mb-8"
                        v-if="categoria.urlImage"
                        v-bind:style="{
                          'background-image': 'url(' + categoria.urlImage + ')',
                        }"
                      ></div>
                      <ListPlatillos
                        :platillos="categoria.platillos.slice(0, 1)"
                        v-if="categoria.platillos"
                      ></ListPlatillos>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListPlatillos from "@/components/ListPlatillos";
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  props: {
    dataVisual: {
      type: Object,
      default: {},
    },
  },
  computed: {
    ...mapState("moduloRestaurantes", ["menuView"]),
  },
  components: {
    ListPlatillos,
  },
};
</script>
<style lang="scss">
.container-phone-parent {
  width: 100%;
  position: relative;
  overflow: hidden;
  .img-celular {
    width: 100%;
    img {
      width: 100%;
    }
  }
}
.containerBanner {
  height: 150px;
  max-height: 500px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}
.fondo-color-edit-menu {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.img-fondo-edit-menu {
  position: absolute;
  inset: 0px;
  z-index: -1;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
  z-index: 1;
  opacity: 0.6;
}
.v-window {
  overflow: unset !important;
}
.container-parent-mobil-visual {
  width: 100%;
  position: sticky;
  top: 120px;
}
.container-align-mobile {
  left: 0;
  max-width: 350px;
  margin: 0 auto;
}
.container-phone {
  position: relative;
  height: 79%;
  width: 92%;
  padding-bottom: 150px;
  overflow-y: auto;
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translateX(-50%);
  &::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  scrollbar-width: none;
  .nav-superior {
    height: 80px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    .align-nav-phone {
      width: 92%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .container-menu-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    .title-menu-config {
      margin-top: 16px;
      text-align: center;
      position: relative;
      z-index: 2;
    }
    .content-catplatillos {
      width: 100%;
      padding: 12px;
    }
  }
  .img-principal-edit {
    position: relative;
    z-index: 2;
    max-height: 80px;
    object-fit: contain;
  }
}
</style>