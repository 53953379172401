<template>
  <div class="container-listsubs mt-8">
    <v-list two-line dense class="mb-10">
      <v-list-item
        v-for="(item, index) in listSubcategorias"
        :key="index"
        class="headerTable"
      >
        <v-list-item-content>
          <v-list-item-title
            v-text="item.title"
            class="text-body-1"
          ></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <div class="d-flex">
            <v-btn @click="editSubcategoria(item)" text small class="px-0">
              <v-icon small class="editBtn">mdi-pencil</v-icon>
            </v-btn>
            <v-btn @click="confirmRemove(item)" text small class="px-0">
              <v-icon small class="editBtn">mdi-delete</v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <!-- modal eliminar -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Estas seguro que deseas eliminar {{ nameDelete }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="closeDeleteModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="black"
              text
              @click="removeSubcategoria()"
              :loading="loadingDelete"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- modal eliminar -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    listSubcategorias: Array,
    idRestaurant: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loadingEdit: false,
      loadingDelete: false,
      dialogDelete: false,
      nameDelete: "",
      itemDelete: {},
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["eliminaSubcategoria"]),
    confirmRemove(item) {
      this.itemDelete = item;
      this.dialogDelete = true;
      this.nameDelete = item.title;
    },
    removeSubcategoria() {
      if (this.itemDelete.idCategoria) {
        this.executeRemoveQuery();
      } else {
        this.$emit("removeSub", this.itemDelete);
      }
    },
    async executeRemoveQuery() {
      this.loadingDelete = true;
      const sendSubDelete = {
        idRestaurante: this.idRestaurant,
        idSub: this.itemDelete.id,
      };
      const result = await this.eliminaSubcategoria(sendSubDelete);
      if (result.res) {
        this.dialogDelete = false;
        this.$emit("removeSub", this.itemDelete);
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
    editSubcategoria(item) {
      this.$emit("editarSub", item);
    },
    closeDeleteModal() {
      this.dialogDelete = false;
      this.nameDelete = "";
      this.itemDelete = null;
    },
  },
};
</script>
<style lang="scss">
.container-listsubs {
  width: 100%;
}
</style>