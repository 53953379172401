<template>
  <div class="container-data mb-4">
    <div class="d-flex justify-end">
      <v-btn @click="reorderShow()" class="px-2 text-caption white--text" color="verde">
        Reordenar
        <v-icon small class="editBtn ml-2">mdi-reorder-horizontal</v-icon>
      </v-btn>
    </div>
    
  </div>
</template>
<script>
export default {
  methods: {
    reorderShow() {
      this.$emit("showOption", "");
    },
  },
};
</script>