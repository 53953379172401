<template>
  <div>
    <div class="d-flex justify-end">
      <v-btn
        color="primary"
        class="white--text pr-2 pl-2 mr-2 btn-margin"
        @click.prevent="habilitarEdit"
        >EDITAR</v-btn
      >
      <v-btn
        color="removeColor"
        @click="openEliminarCategory()"
        class="red--text text--accent-4 pr-2 pl-2 btn-margin"
        >BORRAR</v-btn
      >
    </div>
    <h2 class="mb-8">{{ categoriaItem.title }}</h2>
    <p class="black--text text-h6">Imagen de la categoría</p>
    <v-img
      max-width="100%"
      max-height="200px"
      contain
      position="left"
      :src="categoriaItem.urlImage"
      v-show="categoriaItem.urlImage"
      class="mb-4 img-principal-edit"
    ></v-img>
    <v-img
      max-width="100%"
      max-height="200px"
      contain
      position="left"
      :src="require('@/assets/default.png')"
      v-show="!categoriaItem.urlImage"
      class="mb-4 img-principal-edit"
    ></v-img>
    <div class="d-flex align-center mb-4">
      <p class="text-caption negroClaro mb-0 mr-1">Subcategorías</p>
      <v-divider></v-divider>
    </div>
    <ul
      class="listGeneralCategory"
      v-if="categoriaItem.subcategorias.length > 0"
    >
      <li v-for="(item, index) in categoriaItem.subcategorias" :key="index">
        {{ item.title }}
      </li>
    </ul>
    <p v-if="categoriaItem.subcategorias.length == 0">Sin categorías</p>
    <!-- modal eliminar -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Estas seguro que deseas eliminar {{ nameDelete }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="closeDeleteModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              text
              @click="borraCategoria()"
              :loading="loadingDelete"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- modal eliminar -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    idRest: String,
    itemPassCategoria: {
      type: Object,
      default: {},
    },
  },
  watch: {
    itemPassCategoria: function (newVal, oldVal) {
      this.categoriaItem = Object.assign({}, newVal);
    },
  },
  data() {
    return {
      categoriaItem: Object.assign({}, this.itemPassCategoria),
      loadingDelete: false,
      dialogDelete: false,
      nameDelete: "",
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["eliminaCategoria"]),
    habilitarEdit() {
      this.$emit("editUpdate", "");
    },
    openEliminarCategory() {
      this.dialogDelete = true;
      this.nameDelete = this.categoriaItem.title;
    },
    closeDeleteModal() {
      this.dialogDelete = false;
      this.nameDelete = "";
    },
    async borraCategoria() {
      this.categoriaItem.idRestaurante = this.idRest;
      this.loadingDelete = true;
      const result = await this.eliminaCategoria(this.categoriaItem);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
        this.$emit("closeDelete", "");
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
  },
};
</script>