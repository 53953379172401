<template>
  <div>
    <ul class="list-categorias">
      <li v-for="(item, index) in categoriasData" :key="index">
        <div class="link-categoria">
          <p class="text-h5 title-cat-info" @click="guardarCategoria(item)">
            {{ item.title }}
          </p>
          <div>
            <v-btn @click="editCategoria(item)" text class="px-2 text-caption">
              Editar
              <v-icon small class="editBtn ml-2">mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              @click="openEliminarCategory(item)"
              text
              class="px-2 text-caption"
            >
              Eliminar
              <v-icon small class="editBtn">mdi-delete</v-icon>
            </v-btn>
          </div>
        </div>
        <div
          class="container-listPlatillosCategoria"
          v-if="item.platillos.length > 0"
        >
          <div
            class="data-item-platillo"
            v-for="(item, index) in item.platillos"
            :key="index"
          >
            <div
              class="title-c text-body-2"
              @click="changeSelectedPlatillo(item)"
            >
              {{ item.title }}
            </div>
            <div class="descripcion-c text-body-2">
              {{ item.descripcion }}
            </div>
            <div class="precio-c text-body-2">
              <span>${{ item.precio }}</span>
            </div>
            <div class="buttons-platillo">
              <v-btn @click="editPlatillo(item)" text class="px-2 text-caption">
                Editar
                <v-icon small class="editBtn ml-2">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="openEliminarPlatillo(item)"
                text
                class="px-2 text-caption"
              >
                Eliminar
                <v-icon small class="editBtn">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div
          class="content-platillos-sub"
          v-for="(subcat, index) in item.subcategorias"
          :key="index"
          v-show="item.subcategorias.length > 0"
        >
          <div class="title-platillo">
            <p class="title-sub">{{ subcat.title }}</p>
            <div class="buttons-flex">
              <v-btn
                @click="editCategoria(item)"
                text
                class="px-2 text-caption"
              >
                Editar
                <v-icon small class="editBtn ml-2">mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                @click="openEliminarSubcategoria(subcat)"
                text
                class="px-2 text-caption"
              >
                Eliminar
                <v-icon small class="editBtn">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
          <div
            class="container-listPlatillosCategoria"
            v-if="subcat.platillos.length > 0"
          >
            <div
              class="data-item-platillo"
              v-for="(platSub, index) in subcat.platillos"
              :key="index"
            >
              <div
                class="title-c text-body-2"
                @click="changeSelectedPlatillo(platSub)"
              >
                {{ platSub.title }}
              </div>
              <div class="descripcion-c text-body-2">
                {{ platSub.descripcion }}
              </div>
              <div class="precio-c text-body-2">
                <span>${{ platSub.precio }}</span>
              </div>
              <div class="buttons-platillo">
                <v-btn
                  @click="editPlatillo(platSub)"
                  text
                  class="px-2 text-caption"
                >
                  Editar
                  <v-icon small class="editBtn ml-2">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  @click="openEliminarPlatillo(platSub)"
                  text
                  class="px-2 text-caption"
                >
                  Eliminar
                  <v-icon small class="editBtn">mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </div>
        <v-btn
          color="black"
          text
          small
          class="black--text mt-4"
          @click="agregarProducto(item)"
        >
          <v-icon right dark class="ml-0 mr-2"> mdi-plus </v-icon>
          AGREGAR ARTÍCULO
        </v-btn>
      </li>
    </ul>
    <div class="d-flex justify-center mt-12">
      <v-btn
        color="primary"
        class="white--text pr-8 pl-8 btn-margin"
        @click="cambiarVistaDiseno()"
        >Continuar a diseño</v-btn
      >
    </div>
    <!-- modal eliminar -->
    <v-row justify="center">
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5"
            >Estas seguro que deseas eliminar {{ nameDelete }}?</v-card-title
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="errorColor" text @click="closeDeleteModal"
              >Cancelar</v-btn
            >
            <v-btn
              color="primary"
              text
              @click="borrarGlobal()"
              :loading="loadingDelete"
              >Confirmar</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <!-- modal eliminar -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  props: {
    categoriasData: {
      type: Array,
      default: [],
    },
    idRest: String,
  },
  data() {
    return {
      loadingDelete: false,
      dialogDelete: false,
      nameDelete: "",
      itemDelete: {},
      enabledDeleteHow: "",
    };
  },
  methods: {
    ...mapActions("moduloRestaurantes", [
      "eliminaCategoria",
      "eliminaPlatillo",
      "eliminaSubcategoria",
    ]),
    agregarProducto(item) {
      this.$emit("addProductoCategory", item.id);
    },
    guardarCategoria(item) {
      this.$emit("actualizaItemCat", item);
    },
    editCategoria(item) {
      this.$emit("editItemCat", item);
    },
    editPlatillo(item) {
      this.$emit("editItemPlatillo", item);
    },
    changeSelectedPlatillo(item) {
      this.$emit("actualizaPlatillo", item);
    },
    cambiarVistaDiseno() {
      this.$emit("nextDiseno");
    },
    openEliminarCategory(itemParam) {
      this.enabledDeleteHow = "categoria";
      this.itemDelete = itemParam;
      this.dialogDelete = true;
      this.nameDelete = this.itemDelete.title;
    },
    openEliminarPlatillo(itemParam) {
      this.enabledDeleteHow = "platillo";
      this.itemDelete = itemParam;
      this.dialogDelete = true;
      this.nameDelete = itemParam.title;
    },
    openEliminarSubcategoria(itemParam) {
      this.enabledDeleteHow = "subcategoria";
      this.itemDelete = itemParam;
      this.dialogDelete = true;
      this.nameDelete = itemParam.title;
    },
    closeDeleteModal() {
      this.enabledDeleteHow = "";
      this.dialogDelete = false;
      this.nameDelete = "";
      this.itemDelete = null;
    },
    borrarGlobal() {
      switch (this.enabledDeleteHow) {
        case "platillo":
          this.borrarPlatillo();
          break;
        case "categoria":
          this.borraCategoria();
          break;
        case "subcategoria":
          this.borrarSubcategoria();
          break;
      }
    },
    async borraCategoria() {
      this.itemDelete.idRestaurante = this.idRest;
      this.loadingDelete = true;
      const result = await this.eliminaCategoria(this.itemDelete);
      if (result.res) {
        this.closeDeleteModal();
        this.$emit("resetDeleteCat", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
    async borrarPlatillo() {
      this.itemDelete.idRestaurante = this.idRest;
      this.loadingDelete = true;
      const result = await this.eliminaPlatillo(this.itemDelete);
      if (result.res) {
        this.closeDeleteModal();
        this.$emit("resetDeleteCat", "");
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
    async borrarSubcategoria() {
      this.loadingDelete = true;
      const sendSubDelete = {
        idRestaurante: this.idRest,
        idSub: this.itemDelete.id,
      };
      const result = await this.eliminaSubcategoria(sendSubDelete);
      if (result.res) {
        this.$emit("resetDeleteCat", "");
        this.closeDeleteModal();
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se eliminó correctamente",
        });
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loadingDelete = false;
    },
  },
};
</script>
<style lang="scss">
.list-categorias {
  list-style: none;
  margin: 0;
  padding: 0 !important;
  .link-categoria {
    p {
      margin-bottom: 0;
    }
  }
  li {
    &:not(:last-child) {
      margin-bottom: 35px;
    }
  }
}
.link-categoria {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-right: 8px;
  border-bottom: 2px solid rgba($color: #000000, $alpha: 0.5);
  .title-cat-info {
    cursor: pointer;
    max-width: 50%;
  }
}
.container-listPlatillosCategoria {
  width: 100%;
}
.data-item-platillo {
  padding: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  min-height: 50px;
  .title-c {
    flex-basis: 30%;
    padding-right: 16px;
    cursor: pointer;
  }
  .descripcion-c {
    flex-basis: 25%;
    color: rgba($color: #616161, $alpha: 0.9);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    padding-right: 16px;
  }
  .precio-c {
    flex-basis: 15%;
    display: flex;
    justify-content: center;
    span {
      display: inline-block;
      text-align: center;
      padding: 6px;
      border-radius: 4px;
      background-color: #f7f7f7;
    }
  }
  .buttons-platillo {
    flex-basis: 30%;
    display: flex;
  }
}
.list-subcategorias {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.title-platillo {
  background-color: #f5f5f5;
  padding: 8px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .title-sub {
    margin-bottom: 0;
    max-width: 50%;
  }
  .buttons-flex {
    display: flex;
  }
}
</style>