<template>
  <div class="container-contentNueva px-9">
    <h2 class="mb-8">Nueva categoría</h2>
    <v-form
      ref="formCategory"
      v-model="valid"
      @submit.prevent="nuevaCategoria()"
      lazy-validation
      class="mb-6"
    >
      <v-text-field
        color="black"
        v-model="nombre"
        :rules="nameRules"
        label="Nombre de la categoría*"
        required
        outlined
      ></v-text-field>
      <p class="black--text text-h6">Imagen de la categoría <span class="text-caption opcionalText negroClaro">(opcional)</span></p>
      <p>
        Esta es la imagen que aparecerá como portada de la categoría, te recomendamos subirla en formato.jpg, o .png a un tamaño de 300 pixeles de alto x 1200 pixeles de ancho y con un máximo de 500kb
      </p>
      <DragDrop
        @obtieneFile="asignaImgCategoria"
        idFile="inputImgCategoria"
      ></DragDrop>
      <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Subcategorías</p>
        <v-divider></v-divider>
      </div>
      <p class="black--text text-h6">Subcategoría <span class="text-caption opcionalText negroClaro">(opcional)</span></p>
      <p>Puedes agregar subcategorías para cada categoría</p>
      <ListaSubcategoriasLevel1
        @removeSub="quitSub"
        @editarSub="setEditSub"
        :listSubcategorias="subcategorias"
        v-if="subcategorias.length > 0"
      ></ListaSubcategoriasLevel1>
      <v-btn
        color="black"
        text
        class="black--text"
        v-show="!mostrarAddCategoria && !mostrarUpdateSub"
        @click="showAddSubcategorias()"
      >
        <v-icon right dark class="ml-0 mr-2"> mdi-plus </v-icon>
        AGREGAR SUBCATEGORÍA
      </v-btn>
      <AgregarSubcategorias
        v-show="mostrarAddCategoria"
        @closeSub="closeAddSubcategorias"
        @saveSub="addSubcategoria"
        :idCat="idCategoria"
      ></AgregarSubcategorias>
      <UpdateSubcategorias v-if="editSub" @updateEdit="updateItem" @closeEditSub="closeUpSub" :dataSub="editSub" v-show="mostrarUpdateSub">
      </UpdateSubcategorias>
      <!-- <div class="d-flex align-center mb-4">
        <p class="text-caption negroClaro mb-0 mr-1">Color</p>
        <v-divider></v-divider>
      </div> -->
      <!-- <p class="black--text text-h6">Color de la categoría</p>
      <v-color-picker
        dot-size="20"
        hide-mode-switch
        mode="hexa"
        swatches-max-height="200"
        v-model="colorCaja"
      ></v-color-picker>
      <p class="black--text text-h6">Color del texto de la categoría</p>
      <p>
        Puedes elegir un color de la tipografía principal, te recomendamos
        elegir un buen contraste
      </p>
      <v-color-picker
        dot-size="20"
        hide-mode-switch
        mode="hexa"
        swatches-max-height="200"
        v-model="color"
      ></v-color-picker> -->
      <div class="d-flex justify-center">
        <v-btn
          color="primary"
          class="white--text pr-8 pl-8 mt-8 mr-2 btn-margin"
          :loading="loading"
          type="submit"
          >Guardar categoría</v-btn
        >
        <v-btn
          color="grayLight"
          class="black--text pr-8 pl-8 mt-8 btn-margin"
          @click="cancelarEdit"
          >Cancelar</v-btn
        >
      </div>
    </v-form>
  </div>
</template>
<script>
import DragDrop from "@/components/generales/DragDrop";
import AgregarSubcategorias from "@/components/restaurante/AgregarSubcategorias";
import UpdateSubcategorias from "@/components/restaurante/UpdateSubcategorias";
import ListaSubcategoriasLevel1 from "@/components/restaurante/ListaSubcategoriasLevel1";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      loading: false,
      valid: false,
      idCategoria: "",
      nombre: "",
      colorCaja: "#FFFFFF00",
      color: "",
      nameRules: [(v) => !!v || "El nombre es requerido"],
      imgCategoria: null,
      urlImagenParent: "",
      subcategorias: [],
      mostrarAddCategoria: false,
      mostrarUpdateSub: false,
      editSub: null,
      indexEditSub:null
    };
  },
  props: {
    idRest: String,
  },
  methods: {
    ...mapActions("moduloRestaurantes", ["creaCategoria", "getMenuView"]),
    async nuevaCategoria() {
      //se validan los campos
      if (!this.$refs.formCategory.validate()) return;
      const categoriaNueva = {
        nombre: this.nombre,
        color: this.color,
        colorCaja: this.colorCaja,
        imagen: this.imgCategoria,
        comentarios: "",
        idRestaurante: this.idRest,
        subcategorias: this.subcategorias,
        order: this.categorias.length
      };
      this.loading = true;
      const result = await this.creaCategoria(categoriaNueva);
      if (result.res) {
        this.imgCategoria = null;
        this.urlImagenParent = "";
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente la categoría",
        });
        this.reset();
        this.$emit("closeNuevaCategoria", "");
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error,
        });
      }
      this.loading = false;
    },
    addSubcategoria(item) {
      this.subcategorias.push(item);
    },
    showAddSubcategorias() {
      this.mostrarAddCategoria = true;
      this.mostrarUpdateSub = false;
      this.editSub = null;
      this.indexEditSub = null;
    },
    closeAddSubcategorias() {
      this.mostrarAddCategoria = false;
    },
    quitSub(item) {
      let indexRemove = this.subcategorias.indexOf(item);
      this.subcategorias.splice(indexRemove, 1);
      this.mostrarAddCategoria = false;
      this.mostrarUpdateSub = false;
      this.editSub = null;
      this.indexEditSub = null;
    },
    setEditSub(item){
      this.indexEditSub = this.subcategorias.indexOf(item);
      if(this.indexEditSub !== -1){
        this.editSub = Object.assign({}, item)
        this.mostrarUpdateSub = true;
        this.mostrarAddCategoria = false;
      }
    },
    updateItem(item){
      Object.assign(this.subcategorias[this.indexEditSub], item)
    },
    cancelarEdit() {
      this.$emit("closeNewCat", "");
    },
    closeUpSub(){
      this.mostrarUpdateSub = false;
      this.editSub = null;
      this.indexEditSub = null;
    },
    reset() {
      this.$refs.formCategory.reset();
    },
    asignaImgCategoria(file) {
      this.imgCategoria = file;
    },
  },
  computed:{
    ...mapState("moduloRestaurantes", ["categorias"])
  },
  components: {
    DragDrop,
    AgregarSubcategorias,
    ListaSubcategoriasLevel1,
    UpdateSubcategorias
  },
};
</script>